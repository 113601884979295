<template>
  <v-dialog v-model="shower" persistent max-width="500">
    <v-card>
      <v-card-title>
        <b>{{ $t('trashPage.messageboxheader')}}</b><v-spacer></v-spacer>
        <v-icon :color="color.theme" dense dark size="35">delete</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <br />
      <v-card-text>
        <v-icon color="red">folder</v-icon>
        {{ $t('trashPage.messageboxsubheader')}}
      </v-card-text>
      <br />
      <v-card-text>
        <v-icon color="amber">error_outline</v-icon>
        <b>{{ $t('trashPage.messageboxsubheader2')}}</b>
        <v-icon color="amber">error_outline</v-icon>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="$emit('closedialog')">{{ $t('trashPage.canceldelete')}}</v-btn>
        <!-- <v-btn color="red" dark @click="deletefile(),$emit('closedialog')">{{ $t('trashPage.forcedelete')}}</v-btn> -->
        <v-btn color="red" dark @click="type_delete(),$emit('closedialog')">{{ $t('trashPage.forcedelete')}}</v-btn>
      </v-card-actions>
      <!-- {{type}} -->
    </v-card>
  </v-dialog>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { log } from 'console';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  props: ["show", "filedata","type"],
   data() {
    return {
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataAccountId"
    ]),
    shower: {
      get() {
        if (this.show === true) {
            return this.show;
            }
            return this.show;
      }
    }
  },
  methods: {
    type_delete(){
      console.log("type_delete");
      if(this.type === "trashinbox"){
        console.log("delete_trashinbox");
        this.fn_emptytrashinbox_selected()
      }else if(this.type === "trashoutbox"){
        console.log("delete_trashoutbox");
        this.fn_emptytrashoutbox_selected()
      }
    },
    deletefile() {
      let payload = {
        user_id: this.dataUsername,
        file_id: this.filedata.file_id,
        account_id: this.dataAccountId,
        inbox_id:this.filedata.inbox_id
      };
      console.log("pay",payload);
       this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/delete_file_inbox",
            payload,
          )
          .then(response =>{
              if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "ลบสำเร็จ"
            });
          } else {
            Toast.fire({
              icon: "error",
              title: "ลบถาวรไม่สำเร็จ"
            });
          }
             this.$emit('loadfiles')
          })
    },
    async fn_emptytrashinbox_selected(){
      // this.processloader = true;
      let payload;
        payload = {
          business_id : this.dataAccountActive.business_info.business_id,
          data_type : "inbox",
          inbox_id: [this.filedata.inbox_id]
        };
        console.log("payload",payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/delete_file_inbox_outbox",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log("res", response);
            if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: "OK",
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
            this.$emit('loadfiles')
          });
    },
    async fn_emptytrashoutbox_selected(){
      // this.processloader = true;
      let payload;
        payload = {
          business_id : this.dataAccountActive.business_info.business_id,
          data_type : "outbox",
          inbox_id: [this.filedata.inbox_id]
        };
        console.log("payload",payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/delete_file_inbox_outbox",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log("res", response);
            if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: "OK",
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
            this.$emit('loadfiles')
          });
    },
  }
};
</script>